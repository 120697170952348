import { useState } from 'react'
import moment from 'moment'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TwitterIcon from '@mui/icons-material/Twitter';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SearchIcon from '@mui/icons-material/Search';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import PaidIcon from '@mui/icons-material/Paid';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import Chip from '@mui/material/Chip';
import axios from 'axios'
import Grid from '@mui/material/Grid';

/** 
 * 
 * use yarn dev to complie
 * 
 * scripts that needed in package.json for heroku deploy 
 *  "scripts": {
    "dev": "react-scripts start",
    "start": "serve -s build",
    "build": "react-scripts build",
    "test": "react-scripts test --env=jsdom",
    "eject": "react-scripts eject",
    "heroku-postbuild": "npm run build"
  },
 * 
 */




const textLable = {
  userText: 'Twitter Account',

}


function App ()
{

  const apiURL = 'https://script.google.com/macros/s/AKfycbxEqao1LOn3sARJ23DbMdusHveNM5TDMTWwoQ7MUioNO6RqsrMCpA8l7lNtYTiMzGr3/exec'
  const token = "M#YyU$EqClHZB#LYIsV!UMVaVNWZF9U$QbV7Z9WRM2GWX_GSZ~XUPNCVIZN;I&QWX;EJLATHE_YNBRQ6FYZKUQOMJdPrMsWwT;QT"
  const [ login, setLogin ] = useState( '' );
  const [ account, setAccount ] = useState( '' );
  const [ datas, setDatas ] = useState( null );
  const [ orderData, setOrderData ] = useState( [] );
  const [ trackingData, setTrackingData ] = useState( [] );
  const [ isTrackingLoaded, setTrackingLoaded ] = useState( false );
  const [ checkingTrack, setCheckingTrack ] = useState( '' );
  const [ open, setOpen ] = useState( false );
  const handleOpen = () => setOpen( true );
  const handleClose = () => setOpen( false );

  // useEffect (()=>{
  //   axios.post(apiURL,account).then ( (respond) => { 

  //     console.log(respond);
  //     console.log(setDatas(respond));
  //   })

  // });






  const getTrackingData = ( e ) =>
  {

    // console.log(e.target.value);
    handleOpen( true );
    const track = e.target.value;
    setCheckingTrack( track );
    setTrackingData( [] );
    setTrackingLoaded( false );
    let shippingStatus = [];
    const trackingDatas = [];

    axios.post( 'https://trackapi.thailandpost.co.th/post/api/v1/authenticate/token', {},
      {
        headers: {
          Authorization: "Token " + token,
          'Content-Type': 'application/json',
        }
      } ).then( ( response ) =>
      {
        // console.log(response.data);  // show get token correcty


        axios.post( 'https://trackapi.thailandpost.co.th/post/api/v1/track', {
          status: "all",
          language: "TH",
          barcode: [ track ]
        },
          {
            headers: {
              Authorization: "Token " + response.data.token,
              'Content-Type': 'application/json',
            }
          } ).then( ( response ) =>
          {

            //   console.log( response.data );
            shippingStatus = Object.values( response.data.response.items )[ 0 ];
            var dateObj = new Date( shippingStatus[ 0 ].status_date );
            moment.locale( 'TH' );
            for ( let i = 0; i < shippingStatus.length; i++ )
            {
              dateObj = shippingStatus[ i ].status_date;
              const tracking = {
                date: moment( dateObj, 'DD/MM/YYYY HH:mm' ).format( 'DD/MM/YYYY HH:mm น.' ),
                location: shippingStatus[ i ].location,
                postcode: shippingStatus[ i ].postcode,
                status: shippingStatus[ i ].status_description
              }

              trackingDatas.push( tracking );
            }


            setTrackingData( trackingDatas );
            setTrackingLoaded( true );

          } );


      } );

  }

  const clearLogin = () =>
  {
    setLogin( '' );
    setDatas( null );
    setTrackingLoaded( false );
    setTrackingData( [] );
  }
  const submitHandler = ( e ) =>
  {
    e.preventDefault();
    setCheckingTrack( '' );
    // console.log("submit : "+account);
    const orderDatas = [];
    const getData = "?account=" + account;

    axios.post( apiURL + getData ).then( ( response ) =>
    {
      // console.log(response.data);
      setDatas( response.data );


      if ( !response.data.error )
      {
        const Data = response.data.data;
        for ( let i = 0; i < Data.length; i++ )
        {




          const order = {
            date: Data[ i ].date,
            status: Data[ i ].status,
            items: Data[ i ].items,
            weight: Data[ i ].weight,
            shippingType: Data[ i ].shippingType,
            track: Data[ i ].tracking,
            note: Data[ i ].note,
          }


          orderDatas.push( order );
        } // end loop data[i]
        //  console.log( orderDatas );

        setOrderData( orderDatas.reverse() );
        setLogin( account );
      }


    } );

  }



  return (
    <>



      {/* ===== login section ===== */ }
      { !login ?
        // if not login 
        <Container maxWidth="md">
          <Box sx={ { border: "1px", margin: 5, padding: 4, paddingBottom: 2 } }>
            <Paper variant="outlined" sx={ { p: 5 } }>

              <Typography component="h1" variant="h5">
                ตรวจสอบรายการสั่งซื้อ
              </Typography>
              <Box component="form" onSubmit={ submitHandler } noValidate sx={ { mt: 1 } }>

                <Box sx={ { display: 'flex', alignItems: 'flex-end' } }>
                  <TwitterIcon color='primary' sx={ { color: 'primary', mr: 1, my: 0.5 } } />
                  <TextField
                    variant="standard"
                    id="input-user"
                    name="input-user"
                    label={ textLable.userText }
                    required
                    fullWidth
                    autoFocus
                    onChange={ ( e ) => setAccount( e.target.value ) }
                  />
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={ { mt: 3, mb: 2 } }
                >
                  ตรวจสอบ
                </Button>
              </Box>

            </Paper>
          </Box>
        </Container>

        :
        // if have login 
        <Container maxWidth="md" sx={ { p: 2 } }>

          <Box component="form" onSubmit={ clearLogin } >
            <Paper variant="outlined"
              sx={ {
                p: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline"
              } }>
              <Typography variant="body1" >  รายการสั่งสินค้าของ </Typography>
              <Typography variant="h6" sx={ { ml: 1 } }> { login } </Typography>
              <Button
                type="submit"
                variant="outlined"
                color="info"
                size="small"
                sx={ { ml: 2 } }
              >
                ล้างข้อมูล
              </Button>
            </Paper>
          </Box>

        </Container>
      }
      {/* ===== end of login section ===== */ }




      {/* ===== show post tracking section ===== */ }
      <Modal
        open={ open }
        onClose={ handleClose }
      >



        <Paper variant="outlined" sx={ {
          width: "50%",
          p: 5,
          m: 3,
          mx: "auto",
        } }>



          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item sx={ { mb: 2 } }>
              <Typography
                variant="body1"

              >
                ตรวจสอบสถานะ

              </Typography>
              <Typography
                variant="h6"
              >
                { checkingTrack }
              </Typography>
            </Grid>

            <Grid item  >
              <Button
                type="submit"
                variant="outlined"
                size="small"
                sx={ { mt: 1.5 } }
                onClick={ handleClose }
              >
                ปิดหน้าต่าง
              </Button>
            </Grid>
          </Grid>
          { isTrackingLoaded ?
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="10%" >วันที่</TableCell>
                  <TableCell width="20%" >ที่ทำการ</TableCell>
                  <TableCell width="15%" >รหัสไปรษณีย์</TableCell>
                  <TableCell width="55%"  >สถานะ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { trackingData ? trackingData.map( ( row ) => (


                  <TableRow key={ Math.random() }>
                    <TableCell> { row.date }</TableCell>
                    <TableCell >{ row.location }</TableCell>
                    <TableCell  >{ row.postcode }</TableCell>
                    <TableCell > { row.status }</TableCell>
                  </TableRow>


                ) ) : <></> }
              </TableBody>

            </Table>
            : <Typography
              variant="subtitle1"
              component="h2"
              align='center' color="#999"
              sx={ { my: 6 } }
            > กรุณารอสักครู่... <br />หากไม่แสดงผล อาจมีข้อผิดพลาดหรือไม่สามารถตรวจสอบได้</Typography> }

        </Paper>
      </Modal>

      {/* ===== end of post tracking section ===== */ }

      {/* ===== show order section ===== */ }
      {/* ===== end of show order section ===== */ }



      { datas ?
        // check if there have error?  
        datas.error ?

          // if error, show error message
          <Container maxWidth="xl">
            <Typography variant="h6" component="div" align='center' color="#f44336">{ datas.errorMsg } </Typography>

            <Typography align='center' variant="caption" component="div" color="#464646"> ไม่พบข้อมูล หรือ Access key ผิดพลาด </Typography>

          </Container>
          // if not error 
          :
          <>


            <Grid item xs={ 12 }>
              <Paper sx={ { p: 2, display: 'flex', flexDirection: 'column' } }>
                <Table >
                  <TableHead>
                    <TableRow>
                      <TableCell width="5%">วันที่สั่ง</TableCell>
                      <TableCell width="10%">สถานะ</TableCell>
                      <TableCell width="50%">รายการที่สั่ง</TableCell>
                      <TableCell width="5%" align="center" >น้ำหนัก</TableCell>
                      <TableCell width="15%" align="center" >การจัดส่ง</TableCell>
                      <TableCell width="15%" >Remark</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { orderData.map( ( row ) => (
                      <TableRow key={ Math.random() + "order" }>
                        <TableCell>{ row.date }</TableCell>
                        <TableCell>{
                          {
                            'รอชำระเงิน': <Chip icon={ <PaidIcon /> } label={ row.status } color="error" size="small" />,
                            'ชำระเงินแล้ว': <Chip icon={ <CardTravelIcon /> } label={ row.status } color="success" size="small" />,
                            'รอรวมสินค้า': <Chip icon={ <WarehouseIcon /> } label={ row.status } color="warning" size="small" />,
                            'รอชำระค่าจัดส่ง': <Chip icon={ <PaidIcon /> } label={ row.status } color="warning" size="small" />,
                            'เตรียมจัดส่ง': <Chip icon={ <MoveToInboxIcon /> } label={ row.status } color="info" size="small" />,
                            'จัดส่งเสร็จสิ้น': <Chip icon={ <LocalShippingIcon /> } label={ row.status } size="small" />,
                            'ถึงผู้รับปลายทาง': <Chip icon={ <HowToRegIcon /> } label={ row.status } size="small" />
                          }[ row.status ]
                        }</TableCell>
                        <TableCell>
                          { row.items.map( ( item, index ) => (
                            <>
                              <Grid container key={ Math.random() }>
                                <Grid item xs={ 0.5 }>
                                  { index + 1 }
                                </Grid>
                                <Grid item xs sx={ { whiteSpace: 'pre-line' } } >
                                  { item }
                                </Grid>
                              </Grid>

                              { index === row.items.length - 1 ? <></> : <Box sx={ { my: 2, borderBottom: '1px dashed #ddd' } } /> }


                            </>
                          ) ) }

                        </TableCell>
                        <TableCell align="center" >{ row.weight }</TableCell>
                        <TableCell align="center" >
                          { row.track ?
                            row.track.toString().substr( row.track.length - 2 ).toUpperCase() === "JP" ||
                              row.track.toString().substr( row.track.length - 2 ).toUpperCase() === "TH" ?

                              <Button
                                size="small"
                                variant="outlined"
                                type='button'
                                value={ ( row.track ) }
                                fullWidth
                                onClick={ getTrackingData }
                                sx={ {
                                  pl: 1,
                                  mb: 1.5,
                                } } >
                                { row.track }
                                <SearchIcon sx={ { color: 'primary', pl: 0.5, } } />
                              </Button>
                              : <>
                                <Button sx={ {
                                  mb: 1.5,
                                } }>
                                  <Typography variant="subtitle2" component="div" color="#9e9e9e"
                                    onClick={ () => { navigator.clipboard.writeText( row.track ) } }>
                                    { row.track }
                                    <ContentCopyIcon color="disabled" sx={ { height: '1rem', ml: 0.5, } } />
                                  </Typography>
                                </Button>
                              </>
                            : <></>
                          }

                          <img alt='' width='80%' src=
                            {
                              {
                                'ePacket': "https://drive.google.com/uc?id=1k9Uc4g0SI2NLgJNgDWyWeki8f7AOLN-T",
                                'EMS': "https://drive.google.com/uc?id=1kIZHltXcWdxlAeS_9KTvNDgH69UQ1Dda",
                                'Airmail': "https://drive.google.com/uc?id=1jzcpVO2VOTc-65VvmoJlhQcEC13IOXzy",
                                'Airmail Parcel': "https://drive.google.com/uc?id=1ju1DdD3AgCQ5IvWOQ0On8zW8YZZJkYQ-",
                                'Surface Parcel': "https://drive.google.com/uc?id=1jq_8DbTF77pHVpfSAB2ixsvVH69SwqHv",
                                'kuroneko': "https://drive.google.com/uc?id=1jzFKurFkMEZ1m-ZK5wc5fZmgfi-8OrZf",
                                'Other': "https://drive.google.com/uc?id=11a5KU7GKdVqjW16VPz62I_z0yn8P3XrJ",
                              }[ row.shippingType ]
                            } />
                        </TableCell>

                        <TableCell>
                          <Typography variant="caption" component="div" color="#9e9e9e" sx={ { whiteSpace: 'pre-line' } } >  { row.note }</Typography>
                        </TableCell>
                      </TableRow>
                    ) ) }
                  </TableBody>
                </Table>
              </Paper>
            </Grid>

          </>

        : <>
          <Container maxWidth="xl">
            <Typography variant="subtitle1" component="div" align='center'>ใส่ Twitter Account เพื่อทำการตรวจสอบ </Typography>


          </Container>
        </> }

    </>

  );
}

export default App;
